<template>
	<div class="flex-row align-items-center">
		<CRow class="justify-content-center headimg mb-5">
			<CCol md="12" class="p-0 ">
				<div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
					<h2 class="text-center text-white middle-center"><b>Detail Permohonan <br><span class="text-success">{{detail_data.modules.m_nama}}</span></b></h2>
				</div>
			</CCol>
		</CRow>
		<div class="container">
			<CRow class="justify-content-center">
				<CCol md="12">
					<CCard>
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h3 class="mb-0 text-center">Detail Permohonan</h3>
						</CCardHeader>
						<CCardBody>
							<CTabs>
								<CTab title="Detail Permohonan" active>
									<CRow>
										<div class="col-12 my-3">
											<h3 class="mb-0">Detail Permohonan {{detail_data.kode_permohonan}}</h3>
										</div>
										<div class="col-md">
											<table class="w-100" border="0">
												<tr>
													<th style="width: 250px">No. Surat Permohonan </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_nomor_surat}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Tanggal Surat </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_tanggal}} </td>
												</tr>
												<tr>
													<th style="width: 250px">NIB </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.perusahaan.nib}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Perihal </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_perihal}}</td>
												</tr>
												<tr v-if="detail_data.permohonan.json_penanggung_jawab">
													<th style="width: 250px">Penanggung Jawab Surat Keputusan</th>
													<td style="width: 15px"> : </td>
													<td>{{JSON.parse(detail_data.permohonan.json_penanggung_jawab).nama_penanggung_jwb}} ({{JSON.parse(detail_data.permohonan.json_penanggung_jawab).jabatan_penanggung_jwb}})</td>
												</tr>
												<tr>
													<th style="width: 250px">Jenis Tanaman Perkebunan </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.tanaman_kategori_sub.tks_nama}}</td>
												</tr>
												<tr>
													<th style="width: 250px">Perlakuan Fisik/Kimia </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_perlakuan}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Nama Produsen </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_nama_produsen}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Negara Tujuan </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.negara_pengirim.n_nama}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Nama Penerima </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_nama_pengirim}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Alamat Penerima </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.pr_alamat_pengirim}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Tempat Pengeluaran </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.pelabuhan.pl_code}} - {{detail_data.pelabuhan.pl_nama}} </td>
												</tr>
												<tr>
													<th style="width: 250px">Tujuan Pengeluaran Benih </th>
													<td style="width: 15px"> : </td>
													<td>{{detail_data.permohonan.tujuan_penggunaan_benih_nama}} </td>
												</tr>
												/*<tr>
													<th style="width: 300px;">Tanda Daftar Produsen/Sertifikat</th>
													<td style="width: 15px;">:</td>
													<td></td>
												</tr>
												<tr>
													<td colspan="3">
														<table class="table table-bordered table-sm" id="table-benih">
															<thead class="bg-sapphire">
																<tr>
																	<th>Nomor Sertifikat</th>
																	<th>Nama Perusahaan</th>
																	<th>Ruang Lingkup</th>
																	<th>Berlaku sampai dengan</th>
																	<th>File Tanda Daftar</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		{{tanda_daftar.nomor_sertifikat}}
																	</td>
																	<td>
																		{{tanda_daftar.nama_perusahaan}}
																	</td>
																	<td>
																		{{tanda_daftar.ruang_lingkup}}
																	</td>
																	<td>
																		{{tanda_daftar.berlaku_sampai}}
																	</td>
																	<td>
																		<div  class="btn btn-success" v-on:click="getFileWithLink(tanda_daftar.file.download)"> <i class="fa fa-download"></i> Unduh Tanda Daftar</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
												<tr>
													<th style="width: 250px;">Informasi Tanaman</th>
													<td style="width: 15px;">:</td>
													<td></td>
												</tr>
												<tr>
													<td colspan="3">
														<table class="table table-bordered table-sm" id="table-benih">
															<thead class="bg-sapphire">
																<tr>
																	<th>Asal Usul Tanaman</th>
																	<th>Lokasi Pengambilan</th>
																	<th>Nama Nursery</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<ul>
																			<li v-if="informasi_tanaman.asal_usul.penangkaran.checklist">{{informasi_tanaman.asal_usul.penangkaran.nama}}</li>
																			<li v-if="informasi_tanaman.asal_usul.kultur_jaringan.checklist">{{informasi_tanaman.asal_usul.kultur_jaringan.nama}}</li>
																			<li v-if="informasi_tanaman.asal_usul.hutan.checklist">{{informasi_tanaman.asal_usul.hutan.nama}}</li>
																		</ul>
																	</td>
																	<td>
																		<table class="table table-borderless">
																			<tr>
																				<th>Provinsi</th>
																				<td> : </td>
																				<td>{{informasi_tanaman.lokasi_pengambilan.provinsi_selected.pr_nama}} </td>
																			</tr>
																			<tr>
																				<th>Kota</th>
																				<td> : </td>
																				<td>{{informasi_tanaman.lokasi_pengambilan.kota_selected.k_nama}} </td>
																			</tr>
																			<tr>
																				<th>Alamat Lengkap</th>
																				<td> : </td>
																				<td>{{informasi_tanaman.lokasi_pengambilan.alamat_lengkap}} </td>
																			</tr>
																		</table>
																	</td>
																	<td>{{informasi_tanaman.nama_nurseri}}</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>*/
												<tr>
													<th style="width: 250px">Status </th>
													<td style="width: 15px"> : </td>
													<td>
														<template>
															<div class="badge badge-success" v-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Selesai'">
																Selesai
															</div>
															<div class="badge badge-danger" v-else-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Dibatalkan'">
																Permohonan Dibatalkan
															</div>
															<div class="badge badge-warning" v-else-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Menunggu Perbaikan'">
																{{permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama}} oleh Pemohon
															</div>
															<div class="badge badge-primary" v-else-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Evaluasi Dokumen Teknis'">
																Menunggu {{permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama}} oleh <span class="text-uppercase">{{permohonan_status.status_permohonan_terbaru.status_permohonan.roles.ditjen}}</span>
															</div>
															<div v-else>
																<div class="badge badge-info">
																	Menunggu {{permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama}} oleh <span class="text-uppercase">{{permohonan_status.status_permohonan_terbaru.status_permohonan.roles.ditjen}}</span>
																</div>
															</div>
														</template>
													</td>
												</tr>
												<tr>
													<th style="width: 250px">Data benih </th>
													<td style="width: 15px"> : </td>
													<td> </td>
												</tr>
											</table>
										</div>
										<div class="col-4 text-center" v-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Selesai'">
											<img src="img/approved.jpg" style="width: 150px;" alt=""> <br><br>
											<div class="btn-group">
												
												<div  class="btn btn-success" v-on:click="getFileWithLink(detail_data.dokumen_hasil[0].link)"> <i class="fa fa-download"></i> Unduh Surat Keputusan</div>
											</div>
										</div>
										<div class="col-12">
											<table class="table table-bordered table-sm w-100" id="table-benih" >
												<thead class="bg-sapphire">
													<tr>
														<th style="width: 15px">No</th>
														<th>Jenis Tanaman</th>
														<th>Nama Latin</th>
														<th>Nama Varietas</th>
														<th>Bentuk Benih</th>
														<th>Jumlah</th>
														<th>Jumlah Disetujui</th>
														<th>Satuan</th>
														<th>Nilai <span>(Total Harga dalam USD)</span></th>
														<th>Kode HS</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(value_benih, index_benih) in detail_data.permohonan.permohonan_benih" :key="index_benih">
														<td>{{index_benih+1}}</td>
														<td>{{value_benih.tanaman.t_nama}}</td>
														<td>{{value_benih.pb_nama_latin}}</td>
														<td>{{value_benih.pb_varietas}}</td>
														<td>{{value_benih.bentuk_benih.bb_nama}}</td>
														<td>{{value_benih.pb_jumlah}}</td>
														<td>{{value_benih.pb_jumlah_disetujui}}</td>
														<td>{{value_benih.satuan.s_nama}}</td>
														<td>{{value_benih.pb_nilai_total}}</td>
														<td>{{value_benih.pb_kode_hs}}</td>
													</tr>
                          <template
                            v-if="
                              permohonan_status.status_permohonan_terbaru
                                .status_permohonan.st_nama == 'Selesai'
                            "
                          >
                          <tr style="height: 20px; background-color: #f5f5f5">
                            <td colspan="10"></td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td colspan="2">Total Jumlah Benih diajukan :</td>
                            <td colspan="8">
                              {{ totalPbJumlah }}
                              {{
                                detail_data.permohonan.permohonan_benih[0]
                                  .satuan.s_nama
                              }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td colspan="2">Total Jumlah Benih disetujuai :</td>
                            <td colspan="8">
                              {{ totalPbJumlahDisetujui }}
                              {{
                                detail_data.permohonan.permohonan_benih[0]
                                  .satuan.s_nama
                              }}
                            </td>
                          </tr>
                          <tr style="font-weight: bold">
                            <td colspan="2">
                              Total Nilai (Total Harga dalam USD) :
                            </td>
                            <td colspan="8">{{ totalPbNilaiTotal }}</td>
                            </tr></template>
												</tbody>
											</table>
											<small v-if="benih_aropt">*) Data benih yang berwarna merah dibatalkan oleh tim teknis</small>
										</div>
									</CRow>
								</CTab>
								<CTab title="Dokumen Permohonan" >
									<CRow>
										<CCol md="12" class="mt-3">
											<CTabs variant="pills">
												<CTab title="Dokumen Persyaratan" active>
													<h3 class="mb-0 my-3">Dokumen Persyaratan</h3>
													<table class="table table-sm table-bordered">
														<thead class="bg-dark text-hite">
															<tr class="text-center">
																<th style="width: 15px;">No</th>
																<th>Dokumen Permohonan</th>
																<th style="width: 100px">File</th>
																<th style="width: 250px">Keterangan</th>
															</tr>
														</thead> 
														<tbody>
															<tr v-for="(value_doc_persyaratan, index_doc_persyaratan) in detail_data.permohonan.permohonan_dokumen" :key="index_doc_persyaratan">
																<td>{{index_doc_persyaratan+1}}</td>
																<td>{{value_doc_persyaratan.dokumen_permohonan.dokumen_persyaratan_permohonan.dpp_nama}}</td>
																<td>
																	<template>
																		
																		<div v-if="value_doc_persyaratan.file" target="_blank" class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap" v-on:click="getFile(value_doc_persyaratan.file)"><i class="fa fa-eye"></i> Lihat</div>
																		<div v-else>
																			Data tidak tersedia
																		</div>
																	</template>
																</td>
																<td></td>
															</tr>
														</tbody>
													</table>
												</CTab>
												<CTab title="Dokumen Proses" >
													<h3 class="mb-0 my-3">Dokumen Proses</h3>
													<table class="table table-sm table-bordered">
														<thead class="bg-dark text-hite">
															<tr class="text-center">
																<th style="width: 15px;">No</th>
																<th>Dokumen Permohonan</th>
																<th style="width: 100px">File</th>
															</tr>
														</thead> 
														<tbody>
															<tr>
																<td>1</td>
																<td>Excel Permohonan Benih</td>
																<td><div target="_blank" class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap" v-on:click="getExcelBenih()"><i class="fa fa-download"></i> Unduh</div></td>
															</tr>
															<template>
																<tr v-for="(value_doc_proses, index_doc_proses ) in detail_data.dokumen_proses" :key="index_doc_proses">
																	<td>
																		<template>
																			<span v-if="index_doc_proses == 0">
																				{{index_doc_proses+2}}
																			</span>
																			<span v-else>
																				{{index_doc_proses+1}}
																			</span>
																		</template>
																		
																	</td>
																	<td>{{value_doc_proses.nama_dokumen}}</td>
																	<td>
																		<div target="_blank" class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap" v-on:click="getFileWithLink(value_doc_proses.link)"><i class="fa fa-eye"></i> Lihat</div>
																	</td>
																</tr>
															</template>
														</tbody>
													</table>
												</CTab>
												<CTab title="Dokumen Hasil">
													<h3 class="mb-0 my-3">Dokumen Hasil</h3>
													<table class="table table-sm table-bordered">
														<thead class="bg-dark text-hite">
															<tr class="text-center">
																<th style="width: 15px;">No</th>
																<th>Dokumen Permohonan</th>
																<th style="width: 100px">File</th>
															</tr>
														</thead> 
														<tbody>
															<template>
																<tr v-if="detail_data.dokumen_hasil == '' || (permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama != 'Selesai' && permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama != 'Dibatalkan')">
																	<td colspan="3">Dokumen Belum Tersedia</td>
																</tr>
																<tr v-for="(value_doc_hasil, index_doc_hasil ) in detail_data.dokumen_hasil" :key="index_doc_hasil" v-else>
																	<td>{{index_doc_hasil+1}}</td>
																	<td>{{value_doc_hasil.nama_dokumen}}</td>
																	<td>
																		<template>
																			<div v-if="permohonan_status.status_permohonan_terbaru.status_permohonan.st_nama == 'Dibatalkan' && value_doc_hasil.jenis_dokumen == 'Dokumen Generate'">
																				Dokumen Dibatalkan
																			</div >
																			<div v-else>
																				<div target="_blank" class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap" v-on:click="getFileWithLink(value_doc_hasil.link)"><i class="fa fa-eye"></i> Lihat</div>
																			</div>
																		</template>
																	</td>
																</tr>
															</template>
														</tbody>
													</table>
												</CTab>
											</CTabs>
										</CCol>
									</CRow>
								</CTab>
							</CTabs>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md="12">
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h3 class="mb-0 text-center">Timeline</h3>
						</CCardHeader>
						<CCardBody style="overflow: scroll;" class="p-2">
							<ul class="timeline timeline-horizontal">
								<li class="timeline-item" v-for="(value_timelime,index_timelime) in detail_data.permohonan.permohonan_timeline" :key="index_timelime">
									<template>
										<div class="timeline-badge danger" v-if="value_timelime.status == 'ditolak' || value_timelime.status == 'dibatalkan'">
											<i class="fa fa-times" style="margin-top: 12px"></i>
										</div>
										<div class="timeline-badge success" v-else>
											<i class="fa fa-check" style="margin-top: 12px"></i>
										</div>
									</template>
									<div class="timeline-panel">
										<div class="timeline-heading">
											<b class="timeline-title">{{value_timelime.jabatan || value_timelime.users.roles}}</b>
											<p><small class="text-muted"><i class="fa fa-clock-o"></i> {{value_timelime.pt_tanggal}}</small></p>
										</div>
										<div class="badge badge-info" >{{value_timelime.pt_status_keterangan}}</div><br>
										<div class="timeline-body" v-if="value_timelime.pt_keterangan">
											<b>Catatan : </b><br>
											<p>{{value_timelime.pt_keterangan}}</p>
										</div>
									</div>
								</li>
							</ul>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</div>
	</div>
</template>
<script>
const null_array = [];
	export default {
		name: "DetailPermohonanPemasukanPemohon", 
		data(){
			return{
				idpermohonan: this.$route.params.idpermohonan,
				detail_data: null_array,
				data_benih: null_array,
				permohonan_status: null_array,
				benih_aropt: false,
				tanda_daftar: {
          nomor_sertifikat: null,
          nama_perusahaan: null,
          ruang_lingkup: null,
          berlaku_sampai: null,
          file: {
              nama : '',
              url : '',
          }
				},
				informasi_tanaman: {
          asal_usul: {
              penangkaran : {
                  nama: 'Penangkaran',
                  checklist: false
              },
              kultur_jaringan: {
                  nama: 'Kultur Jaringan',
                  checklist: false
              },
              hutan: {
                  nama: 'Hutan',
                  checklist: false
              }
          },
          lokasi_pengambilan: {
              provinsi_selected: {
                  id : '',
                  pr_nama : ''
              },
              kota_selected: {
                  id : '',
                  k_nama : ''
              },
              alamat_lengkap: ''
          },
          
          nama_nurseri: null,
				}
			}
		},
		methods: {
			getExcelBenih(){
                var win = window.open("https://simpel2.pertanian.go.id/endpoint/public/api/excel-list-benih?permohonan_id="+this.detail_data.permohonan.id+'&nib='+this.detail_data.permohonan.nib, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
            },
			getFileWithLink(files){
				var win = window.open(files, '_blank');
				if (win) {
				    //Browser has allowed it to be opened
				    win.focus();
				} else {
				    //Browser has blocked it
				    alert('Please allow popups for this website');
				}
			},
			getFile(files){
				var win = window.open(this.apiLink + files, '_blank');
				if (win) {
				    //Browser has allowed it to be opened
				    win.focus();
				} else {
				    //Browser has blocked it
				    alert('Please allow popups for this website');
				}
			}
		},
		mounted() {
			$(".select2").select2({
				placeholder: "-- Pilih --",
			});
		},
		created(){
			axios.get(this.apiLink + "api/permohonan/detail", {
				params: {
					id: this.idpermohonan
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
			})
			.then((response) => {
				var res_permohonan = response.data;
				var res_permohonan_data = res_permohonan.data;
				if (res_permohonan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_permohonan.data.message
					});
				}
				else{
					this.detail_data = res_permohonan_data;
					this.data_benih = res_permohonan_data.permohonan_benih;
					this.tanda_daftar = JSON.parse(res_permohonan_data.permohonan.pr_tanda_daftar);
					this.informasi_tanaman = JSON.parse(res_permohonan_data.permohonan.pr_informasi_tanaman);
					var is_benih_aropt = false;
					$.each(res_permohonan_data.permohonan.permohonan_benih, function(index, val) {
						// console.log(val.is_aropt);
						if (!val.is_aropt) {
							is_benih_aropt = true;
						}
					});
					this.benih_aropt = is_benih_aropt;
					// console.log(res_permohonan_data);
				}
			});
			// Get Process Status
			axios.get(this.apiLink + "api/permohonan/status", {
				params: {
					id: this.idpermohonan
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
			})
			.then((response) => {
				var res_permohonan_status = response.data;
				var res_permohonan_status_data = res_permohonan_status.data;
				if (res_permohonan_status.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_permohonan_status.data.message
					});
				}
				else{
					this.permohonan_status = res_permohonan_status_data;
				}
			});
			// console.log(this.session_data);
  },
  computed: {
    totalPbJumlah() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah),
        0
      );
      return parseFloat(total.toFixed(2));
    },
    totalPbJumlahDisetujui() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah_disetujui),
        0
      );
      return parseFloat(total.toFixed(2));
    },
    totalPbNilaiTotal() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_nilai_total),
        0
      );
      return parseFloat(total.toFixed(2));
    },
  },
	};
</script>